import {graphql, useStaticQuery} from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import {Sku} from '../../../types'
import {formatPrice} from '../../../utils/helpers'
import {CheckoutForm} from '..'
import styles from './sku-card.module.css'

type Props = {
  sku: Sku
}

export const SkuCard = ({sku}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "registration-product-picture.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>
        Secret of the Source Code - Beginner&apos;s Course
      </h4>
      <div className={styles.card}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          className={styles.picture}
        />
        <div className={styles.detailsWrapper}>
          <div className={styles.details}>
            <p>
              Kick off your coding adventure with Patch and friends with this
              beginner course that introduces programming fundamentals and much
              more.
            </p>
            <ul>
              <li>nine (9) chapters</li>
              <li>over 150 hours of play</li>
              <li>engaging storytelling</li>
            </ul>
            <p>Learn coding with compassion and creativity:</p>
            <p className={styles.emphasis}>
              Price:
              <span className={styles.amount}>
                {formatPrice(sku.price, sku.currency)}
              </span>
              <span className={styles.previousAmount}>
                {formatPrice(2999, sku.currency)}
              </span>
            </p>
          </div>
          <CheckoutForm sku={sku.id} />
        </div>
      </div>
    </div>
  )
}
