import {graphql, useStaticQuery} from 'gatsby'
import React from 'react'
import {SkuCard} from '..'
import {Sku} from '../../../types'
import styles from './skus.module.css'

type SkusGraphQLQuery = {
  skus: {
    edges: {
      node: Sku
    }[]
  }
}

export const Skus = () => {
  const {skus}: SkusGraphQLQuery = useStaticQuery(graphql`
    query SkusForProduct {
      skus: allStripeSku(sort: {fields: [price]}) {
        edges {
          node {
            id
            active
            currency
            price
            product {
              id
              active
              name
            }
            attributes {
              name
            }
          }
        }
      }
    }
  `)

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        {skus.edges.map(({node: sku}: {node: Sku}) => {
          return (sku.product && sku.product.active) ? <SkuCard key={sku.id} sku={sku} /> : ``
        })}
      </div>
    </section>
  )
}
