import React from 'react'
import {DefaultLayout, Footer, Header} from '../../components/common'
import {Skus} from '../../components/registration'
import styles from './index.module.css'

const logoColors = {
  primary: `#fefce1`,
  secondary: `#9f6ee5`,
  shadow: `transparent`,
}

const Index = () => (
  <DefaultLayout>
    <div className={styles.page}>
      <Header
        logoColors={logoColors}
        title="Registration Success"
        headerClass={styles.headerSection}
      />
      <Skus />
      <Footer />
    </div>
  </DefaultLayout>
)

Index.displayName = `RegistrationPage`

export default Index
