import React, {FormEvent, useState} from 'react'
import {redirectToCheckout} from '../../../services/checkout'
import styles from './checkout-form.module.css'

type Props = {
  sku: string
}

export const CheckoutForm = ({sku}: Props) => {
  const minQuantity = 1
  const maxQuantity = 10

  const [quantity, setQuantity] = useState(1)

  const handleQuantityIncrement = () => {
    if (quantity < maxQuantity) setQuantity(quantity + 1)
  }

  const handleQuantityDecrement = () => {
    if (quantity > minQuantity) setQuantity(quantity - 1)
  }

  const initCheckout = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      await redirectToCheckout(sku, quantity)
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <form onSubmit={initCheckout} className={styles.form}>
      <div className={styles.selector}>
        <label htmlFor="quantity" className={styles.label}>
          Students
        </label>
        <div className={styles.controls}>
          <button
            type="button"
            className={styles.decrease}
            disabled={quantity === minQuantity}
            onClick={handleQuantityDecrement}
          >
            <img src="/images/icons/minus-circle.svg" alt="Decrease quantity" />
          </button>
          <input
            id="quantity"
            name="quantity"
            className={styles.input}
            value={quantity}
            readOnly={true}
          />
          <button
            type="button"
            className={styles.increase}
            disabled={quantity === maxQuantity}
            onClick={handleQuantityIncrement}
          >
            <img src="/images/icons/plus-circle.svg" alt="Increase quantity" />
          </button>
        </div>
      </div>
      <button type="submit" className={styles.button}>
        Pay Now
      </button>
    </form>
  )
}
